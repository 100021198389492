import Head from "next/head";
import Link from "next/link";

const Home: React.FC = () => {
  return (
    <div>
      <Head>
        <title>Landing pages</title>
        <meta name="description" content="Adway landing pages" />
        <link rel="icon" href="/favicon-32x32.png" />
      </Head>
      <Link
        href="/service-professionals/24581/union/customer-service-representative"
        style={{
          color: "transparent",
        }}
      >
        Landing page
      </Link>
    </div>
  );
};

export default Home;
